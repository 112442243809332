import GlobalConfig, { getOfflineUrl, initHillo } from '@/model/global/GlobalSettings'
import { getNiceRestaurantInfo } from '@/model/dataLayer/service/otherService'
import IKUtils from 'innerken-js-utils'
import Servant from '@/model/dataLayer/api/tableAndPerson/Servant'
import router from '@/model/global/router'

export const ServantManageCode = [
  {
    id: 10,
    name: 'dayStatistical',
    children: [
      {
        id: 11,
        name: 'dashboard',
        authName: 'dashboard'
      },
      {
        id: 12,
        name: 'marketingStatistics',
        authName: 'marketingStatistics'
      },
      {
        id: 13,
        name: 'bill',
        authName: 'bill'
      },
      {
        id: 14,
        name: 'staff',
        authName: 'staff'
      },
      {
        id: 15,
        name: 'cashBook',
        locked: true,
        authName: 'cashBook'
      },
      {
        id: 16,
        name: 'exportAndPrint',
        authName: 'exportAndPrint'
      },
      {
        id: 17,
        name: 'voucher',
        locked: true,
        authName: 'voucher'
      },
      {
        id: 18,
        name: 'Datev',
        locked: true,
        authName: 'Datev'
      }
    ]
  },
  {
    id: 20,
    name: 'product',
    children: [
      {
        id: 21,
        name: 'category',
        authName: 'category'
      },
      {
        id: 22,
        name: 'productInfo',
        authName: 'productInfo'
      },
      {
        id: 23,
        name: 'attribute',
        authName: 'attribute'
      },
      {
        id: 24,
        name: 'allergName',
        authName: 'allergName'
      },
      {
        id: 25,
        locked: true,
        name: 'discountRule',
        authName: 'discountRule'
      },
      {
        id: 26,
        name: 'menu',
        authName: 'menu'
      },
      {
        id: 27,
        name: 'HotPotSettings',
        authName: 'HotPotSettings'
      }
    ]
  },
  {
    id: 30,
    locked: true,
    name: 'storage',
    children: [
      {
        id: 31,
        name: 'inventory',
        authName: 'inventory'
      },
      {
        id: 32,
        name: 'changeRecord',
        authName: 'changeRecord'
      },
      {
        id: 33,
        name: 'RawMaterials',
        authName: 'resource'
      },
      {
        id: 34,
        name: 'connection',
        authName: 'connection'
      }
    ]
  },
  {
    id: 40,
    locked: true,
    name: 'reserveSetting',
    children: [
      {
        id: 41,
        name: 'openReserve',
        authName: 'openReserve'
      },
      {
        id: 42,
        name: 'reservationTableManage',
        authName: 'reservationTableManage'
      },
      {
        id: 43,
        name: 'tableRule',
        authName: 'tableRule'
      }
    ]
  },
  {
    id: 50,
    name: 'setting',
    children: [
      {
        id: 51,
        name: 'openTime',
        authName: 'openTime'
      },
      {
        id: 52,
        name: 'tableManage',
        authName: 'tableManage'
      },
      {
        id: 53,
        name: 'pay',
        authName: 'pay'
      },
      {
        id: 54,
        name: 'electronicBilling',
        authName: 'electronicBilling'
      },
      {
        id: 58,
        name: 'TaxType',
        authName: 'TaxType'
      },
      {
        id: 59,
        name: 'OperationLog',
        authName: 'OperationLog'
      },
      {
        id: 55,
        name: 'printer',
        children: [
          {
            id: 551,
            name: 'printerInfo',
            authName: 'printerInfo'
          },
          {
            id: 552,
            name: 'operationRecords',
            authName: 'operationRecords'
          },
          {
            id: 553,
            name: 'print_bill_list',
            authName: 'print_bill_list'
          },
          {
            id: 554,
            name: 'printTemplate',
            authName: 'printTemplate'
          },
          {
            id: 555,
            name: 'freeInfoSheet',
            authName: 'freeInfoSheet'
          }
        ]
      },
      {
        id: 56,
        name: 'storesInformation',
        children: [
          {
            id: 561,
            name: 'restaurantInfo',
            authName: 'restaurantInfo'
          },
          {
            id: 562,
            name: 'restaurantImage',
            authName: 'restaurantImage'
          },
          {
            id: 563,
            name: 'selfServiceOrderingMachine',
            authName: 'selfServiceOrderingMachine'
          }
        ]
      },
      {
        id: 57,
        name: 'marketLabel',
        children: [
          {
            id: 571,
            name: 'ingredientSetting',
            authName: 'ingredientSetting'
          },
          {
            id: 572,
            name: 'ingredientSelect',
            authName: 'ingredientSelect'
          },
          {
            id: 573,
            name: 'SettingSKU',
            authName: 'SettingSKU'
          }
        ]
      }
    ]
  },
  {
    id: 60,
    locked: true,
    name: 'takeawayWeb',
    children: [
      {
        id: 61,
        name: 'takeawayWeb',
        authName: 'takeawayWeb'
      },
      {
        id: 62,
        name: 'distributionDistance',
        authName: 'distributionDistance'
      }
    ]
  }
]

export async function getServantNameByPw () {
  const pw = IKUtils.getQueryString('pw')
  let res = []
  if (pw) {
    res = (await Servant.getList(true))
      .find(it => it.password === pw)
    if (!res) {
      await router.push({ name: 'ErrorPage' })
    }
  } else {
    res = (await Servant.getList(true))
      .find(it => it.permission)
  }
  return res
}

export async function getCurrentAuthPower () {
  const servantName = await getServantNameByPw()
  let currentAuth
  try {
    currentAuth = JSON.parse(servantName.rawAuth).map(it => it.authName)
  } catch (e) {
    currentAuth = []
  }
  return currentAuth
}

export async function useStoreOfflineMode () {
  const hostUrl = IKUtils.getQueryString('Base')
  await initHillo(GlobalConfig.DeviceId, getOfflineUrl(hostUrl))
  try {
    (await getNiceRestaurantInfo())
  } catch (e) {
    await router.push({ name: 'ErrorPage' })
    return
  }
  await getPowerAndJump()
}

export async function getPowerAndJump () {
  const servantName = await getServantNameByPw()
  const currentAuth = await getCurrentAuthPower()
  const res = ServantManageCode.map(it => it.children).flat()
  const firstChildren = res.filter(it => !it.children)
  const secondChildren = res.filter(it => it.children).map(x => x.children).flat()
  const allPowerList = firstChildren.concat(secondChildren)
  if (currentAuth.length === 0 && servantName.permission) {
    servantName.rawAuth = JSON.stringify(allPowerList)
    await router.push({ name: 'dashboard' })
  }
  if (currentAuth.length !== 0) {
    await router.push({ name: currentAuth[0] })
  }
}
